import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Offline Provision Portal User Guide`}</h1>
    <p>{`This guide will help you get started with the Offline Provision Portal (OPP) and
teach you how to use it to provision BD products defined in the Online Provision
Portal.`}</p>
    <InlineNotification kind='info' mdxType="InlineNotification">
      <p>{`OPP is intended only for facilities that `}<em parentName="p">{`do not`}</em>{` have internet connectivity. If
the facility has internet connectivity, it is `}<strong parentName="p">{`highly`}</strong>{` recommended to use the Online
Provision Portal.`}</p>
    </InlineNotification>
    <h2>{`Overview`}</h2>
    <p>{`OPP is a tool to provide the same functionality as the Online Provision Portal, but packaged in to a
single standalone application. OPP consist of a single binary that can be run on any computer and will
provide the same capabilities as Online Provision Portal however it will not require internet connectivity.`}</p>
    <p>{`All automations and executions are local to the facilities network. A Single computer deployment has no external
connectivity requirements, however a multi-box deployment will require a network connection (specific ports listed
below) between the computer that is the “Primary” computer and one (or many) agent computer’s.`}</p>
    <h3>{`OPP Application (runtime)`}</h3>
    <p>{`The OPP Application (runtime) is a single executable program and has no external dependencies such as .NET, SQL, etc. to run.
The runtime contains:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`User Interface - a clone of the Online Provision Portal. Any user that has performed or seen an ES 1.7 deployment will
be instantly familar with the UI as it is the same exact code once the offline bundle is loaded (or the runtime is started in agent mode)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Agent - When an offline bundle is not detected, the runtime will prompt the user for the IP address and Deployment code of
the primary computer where the runtime and bundle are loaded. The agent is responsible for performing all steps of the deployment
on remote components.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Embedded Database - The embedded database tracks the state of the deployment and persists data across reboots.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Nuget Server - The nuget server is a local server that is used to host chocolatey packages used during deployments. This
takes the place of Artifactory (dl.bd.com) used in online deployments.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MQTT Broker - The MQTT broker is used to communicate between the primary computer and the agent computers. This takes the
place of the AWS SSM Agent (BDAgent) used in online deployments.`}</p>
      </li>
    </ul>
    <p>{`It is important to note that the user does not have to be concerned with these components. They are all embedded in to the single
executable and are not required to be managed separately.`}</p>
    <h3>{`Offline Bundle`}</h3>
    <p>{`OPP was designed and developed to re-use all automation documents and processes from the Online
Provision Portal. The tool consumes an “offline bundle” of automations and command documents from the
released Provision Portal documents and executes them in the same manner as Provision Portal.`}</p>
    <p>{`Because of this design, the tool itself has no capabilities to deploy products without an offline bundle.
The offline bundle is required for the tool to know what to do as it contains all the instructions, scripts,
product softwre, etc. The added benefit of this design is that anything that the regular Online Provision
Portal can deploy, the Offline Provision Portal can deploy using a bundle extracted from online.`}</p>
    <p>{`Due to the fact that the offline bundle contains all product software, instructions for installation and (potentially)
secrets used to install the software, the offline bundle is encrypted using AES-256 encryption. A Product Key will
be required to decrypt the bundle.`}</p>
    <InlineNotification kind='info' mdxType="InlineNotification">
      <p>{`There is no relation between offline bundles and the OPP application. They are not released together and any valid bundle
of a released product can be loaded into the OPP application. This allows for the OPP application to be used to deploy
any product that is released in the Online Provision Portal.`}</p>
    </InlineNotification>
    <InlineNotification kind='info' mdxType="InlineNotification">
      <p>{`Only one bundle can be loaded at a time. When loading a new bundle the previous bundle will be deleted`}</p>
    </InlineNotification>
    <p>{`Bundles will be available for download from the Online Provision Portal at a later date and any product that is released
will be available to extract in to an offline bundle. At the current time, only the following products are available:`}</p>
    <ul>
      <li parentName="ul">{`ES 1.7.2/1.7.4 AIO (Server)`}</li>
      <li parentName="ul">{`ES 1.7.2/1.7.4 Multi-Box (Server)`}</li>
    </ul>
    <h2>{`Requirements`}</h2>
    <h3>{`Networking Requirements`}</h3>
    <h4>{`Single Device Deployment`}</h4>
    <p>{`For deployments such as AIO servers, there are no requirements for network connectivity beyond getting the runtime
and offline bundle to the device (this can be done using a USB drive if desired).`}</p>
    <h4>{`Multi-Device Deployment`}</h4>
    <p>{`For deployments such as a 3-box ES Server environment, there are requirements for network connectivity within the
facility’s network. Traffic does not leave the facilities network however the following ports are required between
the Primary OPP Runtime and the Agent computers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`TCP Port`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Direction`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3002`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bidirectional between Primary and Agent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nuget Package Service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bidirectional between Primary and Agent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Artifact Server`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1883`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bidirectional between Primary and Agent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MQTT Broker (Agent communication)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Disk Requirements`}</h3>
    <p>{`The OPP runtime is approximately 130MB in size. Offline Bundles can range in size depending on the product they are bundling.
For all bundles, the required disk space is at least 2x the size of the offline bundle. This is because the OPP runtime must
decrypt the bundle and then extract the contents. Once these operations are performed only the extracted contents are left on
the device.`}</p>
    <p>{`Please refer to the requirements for the product being deployed for disk/space requirements.`}</p>
    <h3>{`Browser Requirements`}</h3>
    <p>{`The UI in the runtime is self-contained and does not have any dependencies on any other software.`}</p>
    <h3>{`Operating System Requirements`}</h3>
    <p>{`The OPP runtime can run on Windows, Linux, and MacOS however the only supported target for a deployment is a Windows
computer. OPP can be run on Linux or MacOS and deploy software to Windows but not vice versa.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`To begin a deployment, you must first obtain the OPP runtime application for your operating system, an offline bundle, and the product key
for the offline bundle. Any requirements for the product being deployed must be met just as with Online Provision Portal (domain join, etc)`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Single Device" mdxType="Tab">
        <h4>{`Copy Files to the Device`}</h4>
        <ol>
          <li parentName="ol">{`Download the latest OPP runtime for Windows `}<a parentName="li" {...{
              "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
            }}>{`here`}</a></li>
          <li parentName="ol">{`Extract the Zip file to a location on your computer`}</li>
          <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the device you wish to deploy product software from the bundle.`}</li>
          <li parentName="ol">{`Download the offline bundle for the product you wish to deploy and copy it to the same location as the OPP runtime.`}</li>
        </ol>
        <br />
        <InlineNotification kind='info' mdxType="InlineNotification">
          <p>{`The Offline Provision Portal.exe application and the offline bundle MUST be in the same folder on the target device.`}</p>
        </InlineNotification>
        <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
        <ol>
          <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
          <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
          <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
          <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
          <li parentName="ol">{`Follow the instructions for deploying the product found in the `}<a parentName="li" {...{
              "href": "/guides/es-server-1.7.2"
            }}>{`User Guide`}</a>{` for the product you are deploying.`}</li>
        </ol>
      </Tab>
      <Tab label="Multi-Box" mdxType="Tab">
        <InlineNotification kind='info' mdxType="InlineNotification">
          <p>{`For a multi-box deployment, one copy of “Offline Provision Portal.exe” must be on each box. The box where you would like to
control the deployment from and interact with the UI (primary) must additionally have the bundle.`}</p>
        </InlineNotification>
        <h4>{`Copy Files to the Devices`}</h4>
        <ol>
          <li parentName="ol">{`Download the latest OPP runtime for Windows `}<a parentName="li" {...{
              "href": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=bd-generic-release&path=pp-offline-runtime%252Fmaster%252F1.0%252Fopp-runtime-windows%252Fopp-runtime-windows_1.0.102.zip&isNativeBrowsing=true"
            }}>{`here`}</a></li>
          <li parentName="ol">{`Extract the Zip file to a location on your computer.`}</li>
          <li parentName="ol">{`In the extracted folder, copy the file: \\astilectron\\output\\windows-amd64\\Offline Provision Portal.exe to any location
on the devices you wish to deploy the product. You must repeat this step for each device in the deployment.`}</li>
          <li parentName="ol">{`Downlaod the offline bundle for the product you wish to deploy and copy it to the same location as the OPP runtime on the
device you wish to be the primary device. This is the device where you will be interacting with the UI.`}</li>
        </ol>
        <h4>{`Launch Offline Provision Portal and enter the product key`}</h4>
        <ol>
          <li parentName="ol">{`Double click on the Offline Provision Portal.exe`}</li>
          <li parentName="ol">{`Wait for the application to launch and prompt for a product key`}</li>
          <li parentName="ol">{`Enter the product key exactly as provided with the bundle. The product key is case sensitive and should be entered
in all caps.`}</li>
          <li parentName="ol">{`Wait for the bundle to be decrypted, extracted, and loaded in to the application. This may take a few minutes.`}</li>
          <li parentName="ol">{`Follow the instructions for deploying the product found in the `}<a parentName="li" {...{
              "href": "/guides/es-server-1.7.2"
            }}>{`User Guide`}</a>{` for the product you are deploying to create
a new deployment.`}</li>
          <li parentName="ol">{`Once the deployment is created, note down the deployment code shown in the UI.`}</li>
        </ol>
        <h4>{`Launch Offline Provision Portal on agent devices`}</h4>
        <ol>
          <li parentName="ol">{`Launch the Offline Provision Portal.exe application on all agent devices.`}</li>
          <li parentName="ol">{`Enter the primary device’s IP address and deployment code in the UI and click Submit`}</li>
          <li parentName="ol">{`Return to the primary server to continue the deployment.`}</li>
          <li parentName="ol">{`Follow the instructions for deploying the product found in the `}<a parentName="li" {...{
              "href": "/guides/es-server-1.7.2"
            }}>{`User Guide`}</a>{` for the product you are deploying`}</li>
        </ol>
      </Tab>
    </Tabs>
    <h2>{`Deploying a Product`}</h2>
    <h3>{`Reboots`}</h3>
    <p>{`During the course of a deployment, several steps may be performed which may require operations such as Operating System reboots. The OPP runtime
will automatically save the current state of the deployment if a reboot step is detected in the automation document and re-launch itself upon
a user logging in to the server. The reboot will be fully managed just as the Online Provision Portal and the user should not attempt to intervene.`}</p>
    <p>{`If the reboot is interrupted and the user re-launches the OPP runtime, the deployment `}<em parentName="p">{`will`}</em>{` immediately resume. If a reboot occurs as part of the
deployment automation, do not attempt to intervene. Wait for the reboot to complete and then log back in to the device that rebooted. The OPP
runtime will automatically launch within a few seconds after logging in and resume the deployment.`}</p>
    <h3>{`Remote Agents`}</h3>
    <p>{`Once the primary server IP address and deployment code are entered in the UI for a remote agent, the runtime will attempt to make a connection to
the primary server. If the connection is successful and the deployment code is correct, the settings are saved to the agent. The IP address and
deployment code do not have to be entered again.`}</p>
    <h3>{`Primary Server/Single Device Bundle Storage`}</h3>
    <p>{`Once the product code has been entered in the UI, the OPP runtime will attempt to decrypt the offline-bundle and extract it to a location on the
primary server. The bundle will be stored in the %APPDATA%\\OPP folder for future use. The bundle key is not required after it has been decrypted and
the runtime will automatically load the bundle from %APPDATA%\\OPP if present.`}</p>
    <InlineNotification kind='info' mdxType="InlineNotification">
      <p>{`Every file within the decrypted extracted bundle is verified on launch of the application. Any modification of the bundle contents
may result in the entire bundle being deleted at which point the user must copy the encrypted bundle back to the device and re-enter the product code.`}</p>
    </InlineNotification>
    <h3>{`Deployment Executions`}</h3>
    <p>{`During the course of deployment, the OPP runtime executes the steps defined in the automation document. These steps most often times utilize powershell
to perform actions. Powershell windows will be opened on the device when the step is executing and should `}<em parentName="p">{`not`}</em>{` be closed or interrupted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      